/* swiper */



.swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}

.swiper-slide {
    flex: 1 0 auto;
}

.sub-slide {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    padding: 0 10%;
    max-width: 50%;
}

.sub-slide > h2 {
    font-size:calc(40px + 20 * (100vw - 320px) / 1080);
    text-transform: uppercase;
    font-weight: 900;
    text-shadow: 1px 1px 2px rgb(255,255,255,0.2);
}

.swiper-nav {
    display: flex;
}

.swiper-nav button.prev {
    background-color: #fff;
}

.swiper-nav button.prev svg, .swiper-nav button.next svg {
    width: 25px;
    fill: var(--color1);
}

.swiper-nav .bn {
    border-radius: 0;
    padding: 20px;
}

.swiper-nav button.next {
    background-color: #E3E2E2;
}






/************* max 1250 **************/ @media screen and (max-width:1250px) {

    .sub-slide {
        top: 60%;
        max-width: 65%;
    }

    .sub-slide > h2 {
        font-size: calc(30px + 15 * (100vw - 320px) / 1080);
    }
}


/************* max 1250 **************/ @media screen and (max-width:850px) {

    [data-swiper='home'] .swiper-slide picture img{
        height: 70vh;
        object-fit: cover;
    }

    .sub-slide {
        max-width: 80%;
    }

}

/************* max 1250 **************/ @media screen and (max-width:550px) {

    .sub-slide > h2 {
        font-size: calc(35px + 25 * (100vw - 320px) / 1080);

    }

    .sub-slide > h2, #artisan, #fabricant {
        text-shadow: 1px 1px 11px rgb(255,255,255,0.9);
    }
}